import React, { useState, useMemo } from 'react';
import InputMask from "react-input-mask";
import { sendNotification } from './Functions'
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';


const ListItem = ({ id, item, name, description, servico, cities, thumbnail, state, city, address, longitude, latitude, phone, email, 
  whatsapp, instagram, facebook, website, order, active, onDelete, loadItem, setEditCondominio, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [stateName, setStateName] = useState(name);
  const [stateDescription, setStateDescription] = useState(description);
  const [stateServico, setStateServico] = useState(servico);
  const [stateThumbnail, setStateThumbnail] = useState(thumbnail);
  const [stateUF, setStateUF] = useState(state);
  const [stateCity, setStateCity] = useState(city);
  const [stateAddress, setStateAddress] = useState(address);
  const [stateLongitude, setStateLongitude] = useState(longitude);
  const [stateLatitude, setStateLatitude] = useState(latitude);
  const [statePhone, setStatePhone] = useState(phone);
  const [stateEmail, setStateEmail] = useState(email);
  const [stateWhatsapp, setStateWhatsapp] = useState(whatsapp);
  const [stateInstagram, setStateInstagram] = useState(instagram);
  const [stateFacebook, setStateFacebook] = useState(facebook);
  const [stateWebsite, setStateWebsite] = useState(website);
  const [stateOrder, setStateOrder] = useState(order);
  const [stateActive, setStateActive] = useState(active);

  var estadosBR = [
    { uf: 'AC', nome: 'Acre' },
    { uf: 'AL', nome: 'Alagoas' },
    { uf: 'AP', nome: 'Amapá' },
    { uf: 'AM', nome: 'Amazonas' },
    { uf: 'BA', nome: 'Bahia' },
    { uf: 'CE', nome: 'Ceará' },
    { uf: 'DF', nome: 'Distrito Federal' },
    { uf: 'ES', nome: 'Espirito Santo' },
    { uf: 'GO', nome: 'Goiás' },
    { uf: 'MA', nome: 'Maranhão' },
    { uf: 'MS', nome: 'Mato Grosso do Sul' },
    { uf: 'MT', nome: 'Mato Grosso' },
    { uf: 'MG', nome: 'Minas Gerais' },
    { uf: 'PA', nome: 'Pará' },
    { uf: 'PB', nome: 'Paraíba' },
    { uf: 'PR', nome: 'Paraná' },
    { uf: 'PE', nome: 'Pernambuco' },
    { uf: 'PI', nome: 'Piauí' },
    { uf: 'RJ', nome: 'Rio de Janeiro' },
    { uf: 'RN', nome: 'Rio Grande do Norte' },
    { uf: 'RS', nome: 'Rio Grande do Sul' },
    { uf: 'RO', nome: 'Rondônia' },
    { uf: 'RR', nome: 'Roraima' },
    { uf: 'SC', nome: 'Santa Catarina' },
    { uf: 'SP', nome: 'São Paulo' },
    { uf: 'SE', nome: 'Sergipe' },
    { uf: 'TO', nome: 'Tocantins' }
];

  // useMemo: executa quando determinado estado for alterado, neste caso o estado 'stateThumbnail'
  const preview = useMemo(() => {
    if(thumbnail !== "" && thumbnail === stateThumbnail){
      return thumbnail;
    } else {
      return stateThumbnail ? URL.createObjectURL(stateThumbnail) : null;
    }
  }, [stateThumbnail]);
  
  function onChangeThumbnailFile(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      //let file_name = e.target.files[0].name;
      //let file_type = e.target.files[0].type;
      //console.log('executou o file change: ' +  file_size);
      //Se maior que um 1mb(1048576)
      if(parseInt(file_size) >= 1048000) {
        toast.error("Imagem não suportada! A imagem deve ter no máximo 1MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }else{
        setStateThumbnail(e.target.files[0])
      }
    }
  };

  async function handleSubmit(e){
    e.preventDefault();
    if(stateName !== "" && stateUF !== "" && stateCity !== ""){
      setLoading(true);
      var fileThumbnail = stateThumbnail;
      //Cria um estrutura Multiform Data;
      const data = new FormData();
      data.append('id', id);
      data.append('name', stateName);
      if(typeof fileThumbnail === "object" && thumbnail !== fileThumbnail.name){
        data.append('thumbnail', thumbnail);
        data.append('file', fileThumbnail);
      }
      if(stateServico && stateServico !== undefined && stateServico !== "")
        data.append('servico', stateServico);
      if(stateDescription && stateDescription !== undefined && stateDescription !== "")
        data.append('description', stateDescription);
      if(stateAddress && stateAddress !== undefined && stateAddress !== "")
        data.append('address', stateAddress);
      if(stateUF && stateUF !== undefined && stateUF !== "")
        data.append('state', stateUF);
      if(stateCity && stateCity !== undefined && stateCity !== "")
        data.append('city', stateCity);
      if(stateLatitude && stateLatitude !== undefined && stateLatitude !== "")
        data.append('latitude', stateLatitude);
      if(stateLongitude && stateLongitude !== undefined && stateLongitude !== "")
        data.append('longitude', stateLongitude);
      if(statePhone && statePhone !== undefined && statePhone !== "")
        data.append('phone', statePhone);
      if(stateEmail && stateEmail !== undefined && stateEmail !== "")
        data.append('email', stateEmail);
      if(stateWhatsapp && stateWhatsapp !== undefined && stateWhatsapp !== "")
        data.append('whatsapp', stateWhatsapp);
      if(stateInstagram && stateInstagram !== undefined && stateInstagram !== "")
        data.append('instagram', stateInstagram);
      if(stateFacebook && stateFacebook !== undefined && stateFacebook !== "")
        data.append('facebook', stateFacebook);
      if(stateWebsite && stateWebsite !== undefined && stateWebsite !== "")
        data.append('website', stateWebsite);
      data.append('order', stateOrder);
      data.append('active', stateActive);
      ///data.append('date', StateCondominioDate);
      //data.append('emotions', emotionsSelected);
      try {
        var route = "";
        if(item === "Condominio"){
          route = "/store-condominio";
        } else if(item === "editCondominio"){
          route = "/store-condominio";
        }
        const response = await Api.api.post(route, data);
        console.log(response);
        if(response.data.hasOwnProperty('_id')){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setToggleItem([]);
          const reloadItem = loadItem;
          reloadItem();
          setLoading(false);
          if(item === "editCondominio"){
            setEditCondominio(false);
          }
          /*if(Number.isInteger(id)){
            console.log("Envia notificação");
            sendNotification(stateName, 'Nova edição disponível no app. Aprecie a leitura!', '*');
          }*/
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("(*) Preencha todos os campos obrigatórios!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
  
  return (
      <li className={item === "Condominio" ? toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList": "dashboardEditCondominio"} style={active || stateActive ? {} : {opacity: 0.4}}>
        {item === "Condominio" ?
          <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
            <div className="col-10">
              {stateCity ?
                <span>{stateName} - {stateCity}</span>
              : <span>{stateName}</span> }
            </div>
            <div className="col-2 text-right">
              <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
            </div>
          </div>
        : <></>}
        {item === "editCondominio" || toggleItem[0] === id ?
          <>
            <hr/>
            <form onSubmit={handleSubmit}>
              <div className="row v-center">
                <div className="col-12">
                  <div className="row">
                    <div className="form-group col-12">
                      {item === "Condominio" ?
                        <label className="switch itemDashboardListSwitch">
                          <input type="checkbox" value={stateActive} checked={stateActive} onChange={() => setStateActive(!stateActive)} />
                          <div className="slider"></div>
                        </label>
                      : <></>}
                      <label className="labelImage">Imagem:</label>
                      <label id="thumbnailCondomino" style={{ backgroundImage: `url(${preview})` }} className={stateThumbnail ? 'has-thumbnail' : ''}>
                        <input type="file" onChange={onChangeThumbnailFile} />
                        <img src={camera} alt="Select img" />
                      </label>
                    </div>
                    <div className="form-group col-12">
                      <label className="labelInput">Nome: *</label>
                      <input
                        disabled={item === "Condominio" ? "" : "disabled"}
                        required 
                        type="text" 
                        className="form-control" 
                        placeholder="Nome"
                        value={stateName}
                        onChange={event => setStateName(event.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="form-group col-md-8 col-12">
                          <label className="labelInput">Cidade: *</label>
                          <input list="dataListCities"
                            disabled={item === "Condominio" ? "" : "disabled"}
                            required 
                            type="text" 
                            className="form-control" 
                            placeholder="Cidade"
                            value={stateCity}
                            onChange={event => setStateCity(event.target.value)}
                          />
                          <datalist id="dataListCities" >
                            {item === "Condominio" ?
                              cities.map((item, key) =>
                                <option key={key} value={item} />
                              )
                            : <></>
                          }
                          </datalist>
                        </div>  
                        <div className="form-group col-md-4 col-12">
                          <label className="labelInput">Estado: *</label>
                            <select className="form-control" onChange={event => setStateUF(event.target.value)} disabled={item === "Condominio" ? "" : "disabled"} required >
                              <option value={stateUF}>{stateUF}</option>
                              {estadosBR.map((estado) => {
                                if(estado.nome !== stateUF){
                                  return(
                                    <option key={estado.uf} value={estado.nome}>{estado.nome}</option>
                                  );
                                }
                              })}
                            </select>
                        </div>
                        <div className="col-12">
                          <div className="row">
                            <div className="form-group col-md-12 col-12">
                              <label className="labelInput">Endereço: {item === "editCondominio" ? "*" : ""}</label>
                              <input 
                                required={item === "editCondominio" ? true : false} 
                                type="text" 
                                className="form-control" 
                                placeholder="Endereço do condomínio"
                                value={stateAddress}
                                onChange={event => setStateAddress(event.target.value)}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-12">
                              <label className="labelInput">Descrição: </label>
                              <textarea className="form-control" value={stateDescription} rows="4" onChange={event => setStateDescription(event.target.value)} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-12 col-md-6">
                              <label className="labelInput">Telefone de Contato (Síndico): {item === "editCondominio" ? "*" : ""}</label>
                              <InputMask 
                                mask="(99) 9999-9999?"
                                maskChar="" 
                                formatChars={{
                                  "9": "[0-9]",
                                  "?": "[0-9]"
                                }}
                                className="form-control" 
                                placeholder="Número de Telefone"
                                value={statePhone}
                                onChange={event => setStatePhone(event.target.value)}
                              />
                            </div>  
                            <div className="form-group col-12 col-md-6">
                              <label className="labelInput">WhatasApp de Contato (Síndico):</label>
                              <InputMask 
                                mask="(99) 99999-9999"
                                maskChar=""
                                className="form-control" 
                                placeholder="Número de WhatasApp"
                                value={stateWhatsapp}
                                onChange={event => setStateWhatsapp(event.target.value)}
                              />
                            </div>
                          </div>
                          {/*<div className="row">
                            <div className="form-group col-12 col-md-6">
                            <label className="labelInput">Email de Contato (Síndico):</label>
                              <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Endereço de email"
                                value={stateEmail}
                                onChange={event => setStateEmail(event.target.value)}
                              />
                            </div>  
                          </div>*/}
                        </div>
                      </div>
                      {/*item === "Shop" ?
                        <div className="row">
                          <div className="form-group col-6">
                            <label className="labelInput">Latitude:</label>
                            <input 
                              type="number" 
                              className="form-control" 
                              placeholder="Latitude"
                              value={stateLatitude}
                              onChange={event => setStateLatitude(event.target.value)}
                            />
                          </div>  
                          <div className="form-group col-6">
                            <label className="labelInput">Longitude:</label>
                            <input 
                              type="number" 
                              className="form-control" 
                              placeholder="Longitude"
                              value={stateLongitude}
                              onChange={event => setStateLongitude(event.target.value)}
                            />
                          </div>
                          <a href="https://www.latlong.net/" className="linkListItem" target="_Blank">Obter a latitude e longitude</a>
                        </div>
                      : <></> */}
                    </div>
                    {/*
                    <div className="col-12">
                      <div className="row">
                        <div className="form-group col-12 col-md-6">
                          <label className="labelInput">Instagram:</label>
                          <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Usuário (@) do Instagram"
                            value={stateInstagram}
                            onChange={event => setStateInstagram(event.target.value)}
                          />
                        </div>  
                        <div className="form-group col-12 col-md-6">
                          <label className="labelInput">Facebook:</label>
                          <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Link do Facebook"
                            value={stateFacebook}
                            onChange={event => setStateFacebook(event.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group col-12">
                        <label className="labelInput">Prioridade:</label>
                        <input 
                          type="number" 
                          className="form-control" 
                          placeholder="Número de ordenação"
                          value={stateOrder}
                          onChange={event => setStateOrder(event.target.value)}
                        />
                      </div>
                    </div>   */}    
                  </div>     
                </div>     
                {item === "editCondominio" ?  
                  <div className="col-12 text-center">
                    <button type="button" className="btn btn-delete" onClick={() => setEditCondominio(false)}> Fechar </button>
                      <span>&nbsp;&nbsp;&nbsp;</span>
                    <button type="submit" className="btn btn-default"> Salvar Alterações</button>
                    <br />
                    <br />
                  </div>
                :  
                  <div className="col-12 text-right">
                    <button type="button" className="btn btn-delete" onClick={onDelete}> Excluir </button>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <button type="submit" className="btn btn-default"> Salvar </button>
                  </div>
                }
              </div>
            </form>
          </>
        : '' }
      </li>
  );
};

export default ListItem;
