//useEffect = Método chamado ao carragar a página;
import React, { useContext, useEffect, useState} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import ListItemNotice from '../../components/ListItemNotice'
import './styles.css';
import '../../App.css';
import UserContext from '../../contexts/UserContext';

export default function DashboardNotices({setLoading}) {
  const [notices, setNotices] = useState([]);
  const {user, setUser} = useContext(UserContext);

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadNotices();
  }, []);

  async function loadNotices() {
    try {
      const response = await Api.get("/get-notices-condominio/"+user[0].idCondominio._id);
      setNotices(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }
  
  async function addNotice(){
    const noticeCopy = Array.from(notices);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    noticeCopy.unshift({
      _id: noticeCopy.length +1+"",
      user: { _id: user[0]._id, name: user[0].name },
      guest: "",
      observation: "",
      rg: "",
      date: new Date().toISOString(),
      condominio: user[0].idCondominio._id,
      active: true
    });
    await setNotices(noticeCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(noticeCopy.length)
  }

  async function removeNotice(id){
    setLoading(true);
    const noticeCopy = Array.from(notices);
    var index = noticeCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.post('/delete-notice', {id: id});
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          noticeCopy.splice(index, 1);
          setNotices(noticeCopy);
        } else {
          if(response?.message){
            toast.error(response.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error("Falha ao realizar a operação!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    } else {
      noticeCopy.splice(index, 1);
      setNotices(noticeCopy);
    }
    setLoading(false);
  }

  return (
    <div id="noticeList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-4">
          <span className="title">Avisos de Entrada</span>
        </div>
        <div className="col-4 text-center">
        </div>
        {/*<div className="col-4 text-right">
          <button type="button" onClick={addNotice} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
        </div>*/}
      </div>
      <div className="col-12">
        <ul className="dashboardList">
          {notices.length > 0 ?
          notices.map(item => (
            <ListItemNotice
              key={item._id}
              id={item._id}
              user={item.user}
              guest={item.name}
              description={item.observation}
              date={item.date} 
              rg={item.rg} 
              condominio={item.condominio} 
              active={item.active} 
              onDelete={() => removeNotice(item._id)}
              loadItem={() => loadNotices()}
              setLoading={setLoading}
              //ref={childRef}
            />
          )) :
          <div className="col-12 text-center">
            <br /><br /><br />
            <span className="labelFilter">Não há avisos para mostrar!</span>
          </div>}
        </ul>
      </div>
    </div>
  )
 
}