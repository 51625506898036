import axios from 'axios';

//var BASE_API = 'http://appviaki.com.br/api';
//var BASE_URL = 'http://appviaki.com.br';
var BASE_API = 'https://server-bof3vdfxvq-rj.a.run.app/api';
var BASE_URL = 'https://server-bof3vdfxvq-rj.a.run.app';
//var BASE_API = 'http://192.168.1.108:3000/api';
//var BASE_URL = 'http://192.168.1.108:3000';
const api = axios.create({
  baseURL: BASE_API
});

// CADASTRO E LOGIN USER
const registerUser = async (name, email, city, state, pass, idCondominio) => {
  try {
    const response = await api.post('/store-user', {
      "name": name,
      "email": email,
      "city": city,
      "state": state,
      "password": pass,
      "idCondominio": idCondominio,
      "token": "",
      "admin": false,
		  "prestador": false,
      "active": false
    });
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const updateMorador = async (id, adminCondominio, active) => {
  try {
    const response = await api.post('/update-user', {
      id,
      "adminCondominio": adminCondominio,
      "active": active
    });
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const deleteMorador = async (id) => {
  try {
    const response = await api.post('/delete-user', {id});
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const login = async (email, pass) => {
  if(email && pass){
    try {
      const response = await api.post('/login/', {
        "email": email,
        "senha": pass
      });
      if(response){
        return response.data;
      } else {
        return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
      }
    } catch (error) {
      console.log(error);
      return {message: error.errmsg, success: false};
    }
  } else {
    return {message: "Por favor, informe seu e-mail e sua senha!", success: false};
  }
}

// CADASTRO E LOGIN PRESTADOR
const registerPrestador = async (name, email, city, state, pass, servicoSelected) => {
  try {
    const response = await api.post('/store-prestador', {
      "name": name,
      "email": email,
      "city": city,
      "state": state,
      "password": pass,
      "idServico": servicoSelected,
      "token": ""
    });
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const updatePrestador = async (id, active) => {
  try {
    const response = await api.post('/update-prestador', {
      id,
      active
    });
    if(response.hasOwnProperty("data")){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const deletePrestador = async (id) => {
  try {
    const response = await api.post('/delete-prestador', {id});
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const updateCondominiosListPrestador = async (id, condominiosSelected) => {
  try {
    const response = await api.post('/update-prestador', {
      id,
      condominios: condominiosSelected,
    });
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}

const loginPrestador = async (email, pass) => {
  if(email && pass){
    try {
      const response = await api.post('/login-prestador/', {
        "email": email,
        "senha": pass
      });
      if(response){
        return response.data;
      } else {
        return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
      }
    } catch (error) {
      console.log(error);
      return {message: error.errmsg, success: false};
    }
  } else {
    return {message: "Por favor, informe seu e-mail e sua senha!", success: false};
  }
}

//GET USER / PRESTADOR
const getUser = async (user_id) => {
  try {
    const response = await api.get(`/get-users/${user_id}`);
    if(response){
      return response.data[0];
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const getUsers = async () => {
  try {
    const response = await api.get(`/get-users`);
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const getUsersCondominio = async (idCondominio) => {
  try {
    const response = await api.get(`/get-users-condominio/${idCondominio}`);
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const loadUserLogged = async () => {
  try {
    const userStorage = await sessionStorage.getItem('user') || '[]';
    console.log(userStorage);
    var user = JSON.parse(userStorage);
    if(user.length > 0){
      var response = [];
      if(user[0].hasOwnProperty("idServico") && user[0].idServico){
        response = await api.get(`/get-prestadores/${user[0]._id}`);
      } else {
        response = await api.get(`/get-users/${user[0]._id}`);
      }
      if(response){
        return response.data[0];
      } else {
        return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
      }
    } else {
      return {message: "Usuário não logado!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const updatePass = async (id, oldPass, newPass) => {
  if(oldPass && newPass){
    try {
      const response = await api.post('/update-user', {
        id: id,
        oldPass,
        newPass
      });
      if(response){
        return response.data;
      } else {
        return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
      }
    } catch (error) {
      console.log(error);
      return {message: error.errmsg, success: false};
    }
  } else {
    return {message: "Por favor, preencha todos os campos!", success: false};
  }
}
const resetPass = async (email) => {
  if(email){
    try {
      const response = await api.post('/reset-pass', {
        email
      });
      if(response){
        return response.data;
      } else {
        return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
      }
    } catch (error) {
      console.log(error);
      return {message: error.errmsg, success: false};
    }
  } else {
    return {message: "Por favor, informe o seu e-mail cadastrado!", success: false};
  }
}

// Condominios
const getCondominios = async () => {
  const response = await api.get(`/get-condominios`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getCondominiosCidade = async (city) => {
  const response = await api.get(`/get-condominios-cidade/${encodeURI(city)}`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getCondominio = async (id) => {
  const response = await api.get(`/get-condominios/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

// Businesses | Comércios Locais
const getBusinesses = async () => {
  const response = await api.get(`/get-businesses`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getBusinessCidade = async (city) => {
  const response = await api.get(`/get-businesses-cidade/${encodeURI(city)}`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getBusiness = async (id) => {
  const response = await api.get(`/get-businesses/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

// Servicos | Categorias
const getCategories = async (category) => {
  const response = await api.get(`/get-categories-active/true/${encodeURI(category)}`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getCategory = async (id) => {
  const response = await api.get(`/get-categories/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

// Prestadores
const getPrestadores = async () => {
  const response = await api.get(`/get-prestadores`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getPrestador = async (id) => {
  const response = await api.get(`/get-prestadores/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

// Promoções
const getPromotions = async () => {
  const response = await api.get(`/get-promotions`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getPromotion = async (id) => {
  const response = await api.get(`/get-promotions/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getPromotionsCondominio = async (idCondominio) => {
  const response = await api.get(`/get-promotions-condominio/${idCondominio}`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

// Mural
const getMuralCondominio = async (idCondominio) => {
  const response = await api.get(`/get-mural-condominio/${idCondominio}`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getMural = async (id) => {
  const response = await api.get(`/get-mural/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const storePostMural = async (author, condominio, description) => {
  if(condominio && description && description !== ""){
    try {
      const response = await api.post('/store-mural', {
        author: author,
        condominio: condominio,
        description
      });
      if(response){
        return response.data;
      } else {
        return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
      }
    } catch (error) {
      console.log(error);
      return {message: error.errmsg, success: false};
    }
  } else {
    return {message: "Por favor, informe alguma mensagem!", success: false};
  }
}

// Shop
const getShopsCondominio = async (idCondominio) => {
  const response = await api.get(`/get-shops-condominio/${idCondominio}`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getShop = async (id) => {
  const response = await api.get(`/get-shops/${id}`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const storePostShop = async (author, condominio, name, description, price, linkContato) => {
  if(author !== "" && condominio !== "" && name !== "" && price !== ""){
    try {
      const response = await api.post('/store-shop', {
        author,
        condominio,
        name,
        description,
        price,
        linkContato
      });
      if(response){
        return response.data;
      } else {
        return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
      }
    } catch (error) {
      console.log(error);
      return {message: error.errmsg, success: false};
    }
  } else {
    return {message: "Por favor, preencha todos os campos!", success: false};
  }
}

//Planos
const getPlanos = async () => {
  const response = await api.get(`/get-plans/`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getPlano = async (id) => {
  const response = await api.get(`/get-plans/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

//Notificações
const sendNotification = async (title, body, filter, token, user) => {
  const response = await api.post('/send-notification', {
    title,
    body,
    filter, 
    token,
    user
  });
  return response;
}
//Email
const sendEmail = async (formData) => {
  const response = await api.post('/send-email', formData);
  return response;
}

const loadDataAtual = async () => {
  const response = await api.get('/data-hora-atual');
  return response.data.dataHoraAtual;
}

//Métodos Universais
const post = async (route, data) => {
  try {
    const response = await api.post(route, data);
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const get = async (route) => {
  const response = await api.get(route);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

export default {
  api,
  BASE_API,
  BASE_URL,
  registerUser,
  updateMorador,
  deleteMorador,
  login,
  loginPrestador,
  registerPrestador,
  updatePrestador,
  deletePrestador,
  updateCondominiosListPrestador,
  loadUserLogged,
  updatePass,
  resetPass,
  getUser,
  getUsers,
  getUsersCondominio,
  getCondominios,
  getCondominiosCidade,
  getCondominio,
  getBusiness,
  getBusinesses,
  getBusinessCidade,
  getCategories,
  getCategory,
  getPrestadores,
  getPrestador,
  getPromotions,
  getPromotion,
  getPromotionsCondominio,
  getMural,
  getMuralCondominio,
  storePostMural,
  getShop,
  getShopsCondominio,
  storePostShop,
  getPlano,
  getPlanos,
  sendNotification,
  sendEmail,
  loadDataAtual,
  post,
  get
};