import React, { useState, useMemo, useEffect } from 'react';
import InputMask from "react-input-mask";
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';

const ListItemBooking = ({ id, user, space, description, guests, dateStart, dateEnd, condominio, status, active, spaces, onDelete, loadItem, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [stateName, setStateName] = useState(user.name);
  const [stateSpace, setStateSpace] = useState(space);
  const [stateDescription, setStateDescription] = useState(description);
  const [stateBookingDate, setStateBookingDate] = useState(dateStart.substr(0,10));
  const [stateBookingHour, setStateBookingHour] = useState(dateStart.substr(11,5));
  const [stateStatus, setStateStatus] = useState(status);
  const [stateActive, setStateActive] = useState(active);

  async function handleSubmit(e){
    e.preventDefault();
    if(stateSpace._id !== "" && stateName !== "" && stateBookingHour !== "" && stateBookingDate !== ""){
      //console.log(new Date(stateBookingDate+" "+stateBookingHour));
      //return false;
      setLoading(true);
      //Cria um estrutura Multiform Data;
      const data = {
        id: id,
        condominio: condominio,
        space: stateSpace._id,
        user: user._id,
        name: stateName.trim(),
        status: stateStatus.trim(),
        dateStart: new Date(new Date(stateBookingDate+" "+stateBookingHour).getTime() - (180 * 60000)).toISOString(),
        observation: stateDescription.trim(),
        timeBooking: stateSpace.timeBooking,
      }
      /*data.append('id', id);
      data.append('condominio', condominio);
      data.append('space', stateSpace);
      data.append('user', user._id);
      data.append('name', stateName.trim());
      data.append('status', stateStatus.trim());
      data.append('dateStart', new Date(new Date(stateBookingDate+" "+stateBookingHour).getTime() - (180 * 60000)).toISOString());
      data.append('observation', stateDescription.trim());*/
      //data.append('active', stateActive);
      try {
        const response = await Api.post("/store-booking", data);
        console.log(response);
        if(response.hasOwnProperty('_id') || response?.ok){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setToggleItem([]);
          const reloadItem = loadItem;
          reloadItem();
          setLoading(false);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("(*) Preencha todos os campos obrigatórios!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
  
  return (
      <li className={toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList"} style={active || stateActive ? {} : {opacity: 0.4}}>
        <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
          <div className="col-10">
            <span>{stateBookingDate.split("-").reverse().join("/")} às {stateBookingHour}h - {space.name}</span>
          </div>
          <div className="col-2 text-right">
            {status === "Solicitado" ?
              <span className={`iconItem fas fa-exclamation-triangle`} style={{color: 'rgb(255 189 3)'}}></span>
            : <></> }
            {status === "Agendado" ?
              <span className={`iconItem fas fa-check-circle`} style={{color: 'green'}}></span>
            : <></> }
            {status === "Recusado" ?
              <span className={`iconItem fas fa-ban`} style={{color: 'red'}}></span>
            : <></> }
            <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItem[0] === id ?
          <div className="row">
            <div className="col-12">
              <hr/>
              <form onSubmit={handleSubmit}>
                <div className="row v-center">
                  <div className="col-12">
                    {/*<label className="switch itemDashboardListSwitch">
                      <input type="checkbox" value={stateActive} checked={stateActive} onChange={() => setStateActive(!stateActive)} />
                      <div className="slider"></div>
                    </label>*/}
                    <div className="row">
                      <div className="form-group col-12">
                        <label className="labelInput">Morador: *</label>
                        <input 
                          disabled="disabled"
                          type="text" 
                          className="form-control" 
                          placeholder="Nome do morador"
                          value={stateName}
                          onChange={event => setStateName(event.target.value)}
                        />
                      </div>
                      <div className="form-group col-6">
                        <label className="labelInput">Espaço: *</label>
                        <select className="form-control" onChange={event => { var index = event.nativeEvent.target.selectedIndex; setStateSpace({_id: event.target.value, name: event.nativeEvent.target[index].text})}} value={stateSpace._id}>
                          {spaces.map((item) => (
                            <option key={item._id} value={item._id}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group col-6">
                        <label className="labelInput">Data da Reserva: *</label>
                        <input 
                          type="date" 
                          className="form-control" 
                          placeholder="Data de reserva"
                          value={stateBookingDate}
                          onChange={event => setStateBookingDate(event.target.value)}
                        />
                      </div>
                      <div className="form-group col-6">
                        <label className="labelInput">Hora de Início: *</label>
                        <input 
                          type="time" 
                          className="form-control" 
                          placeholder="Hora de reserva"
                          value={stateBookingHour}
                          onChange={event => setStateBookingHour(event.target.value)}
                        />
                      </div>
                      <div className="form-group col-6">
                        <label className="labelInput">Hora de Fim: </label>
                        <input 
                          disabled="disabled"
                          type="time" 
                          className="form-control" 
                          placeholder="Hora de reserva"
                          value={dateEnd.substr(11,5)}
                          //onChange={event => setStateBookingHour(event.target.value)}
                        />
                      </div>
                      <div className="form-group col-12">
                        <label className="labelInput">Observação: </label>
                        <textarea 
                          className="form-control" 
                          placeholder="..."
                          value={stateDescription} 
                          onChange={event => setStateDescription(event.target.value)} 
                          />
                      </div>
                      <div className="form-group col-12">
                        <label className="labelInput">Status: </label>
                        <select className="form-control" onChange={(event) => setStateStatus(event.target.value)} value={stateStatus}>
                          <option>Solicitado</option>
                          <option>Agendado</option>
                          <option>Recusado</option>
                        </select>
                      </div>
                    </div>     
                      {guests.length > 0 ?
                      <>
                        <h6><b>Lista de Convidados</b></h6>
                        <div className="form-group col-12">
                          <div style={{maxHeight: 205, overflow: "auto"}}>
                            {guests.map(item => (
                              <div style={{}}>
                                <p style={{marginBottom: 3}}>{item.name} - RG: {item.rg}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                      : <></>}  
                  </div>     
                      
                  <div className="col-12 text-right">
                    <button type="button" className="btn btn-delete" onClick={onDelete}> Excluir </button>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <button type="submit" className="btn btn-default"> Salvar </button>
                  </div>
                </div>
              </form>
                
            </div>
          </div>
          : '' }
      </li>
  );
};

export default ListItemBooking;
