import React from 'react';
import Api from '../services/Api'

// Convert a time in hh:mm format to minutes
function timeToMins(time) {
  var b = time.split(':');
  return b[0]*60 + +b[1];
}

// Convert minutes to a time in format hh:mm
// Returned value is in range 00  to 24 hrs
function timeFromMins(mins) {
  function z(n){return (n<10? '0':'') + n;}
  var h = (mins/60 |0) % 24;
  var m = mins % 60;
  return z(h) + ':' + z(m);
}

const Functions = {
  /*sendNotification: async function(title, body, token, user) {
      const response = await Api.api.post('/send-notification', {
              title,
              body,
              token,
              user
          }
      );
      return response;
  },
  loadDataAtual: async function(){
      const response = await Api.api.get('/data-hora-atual');
      return response.data.dataHoraAtual;
  },*/
  validateEmail: (email) => {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
        return (true)
      } else{
        return (false)
      }
  },
  checkMonth: function (month){
    var arrMonth=new Array();
    arrMonth[1]='Jan';
    arrMonth[2]='Fev';
    arrMonth[3]='Mar';
    arrMonth[4]='Abr';
    arrMonth[5]='Mai';
    arrMonth[6]='Jun';
    arrMonth[7]='Jul';
    arrMonth[8]='Ago';
    arrMonth[9]='Set';
    arrMonth[10]='Out';
    arrMonth[11]='Nov';
    arrMonth[12]='Dez';
    return arrMonth[parseInt(month)];
  },
  // Add two times in hh:mm format
  addTimes(time0, time1) {
    return timeFromMins(timeToMins(time0) + timeToMins(time1));
  },
  estadosBR: [
    { uf: 'AC', nome: 'Acre' },
    { uf: 'AL', nome: 'Alagoas' },
    { uf: 'AP', nome: 'Amapá' },
    { uf: 'AM', nome: 'Amazonas' },
    { uf: 'BA', nome: 'Bahia' },
    { uf: 'CE', nome: 'Ceará' },
    { uf: 'DF', nome: 'Distrito Federal' },
    { uf: 'ES', nome: 'Espirito Santo' },
    { uf: 'GO', nome: 'Goiás' },
    { uf: 'MA', nome: 'Maranhão' },
    { uf: 'MS', nome: 'Mato Grosso do Sul' },
    { uf: 'MT', nome: 'Mato Grosso' },
    { uf: 'MG', nome: 'Minas Gerais' },
    { uf: 'PA', nome: 'Pará' },
    { uf: 'PB', nome: 'Paraíba' },
    { uf: 'PR', nome: 'Paraná' },
    { uf: 'PE', nome: 'Pernambuco' },
    { uf: 'PI', nome: 'Piauí' },
    { uf: 'RJ', nome: 'Rio de Janeiro' },
    { uf: 'RN', nome: 'Rio Grande do Norte' },
    { uf: 'RS', nome: 'Rio Grande do Sul' },
    { uf: 'RO', nome: 'Rondônia' },
    { uf: 'RR', nome: 'Roraima' },
    { uf: 'SC', nome: 'Santa Catarina' },
    { uf: 'SP', nome: 'São Paulo' },
    { uf: 'SE', nome: 'Sergipe' },
    { uf: 'TO', nome: 'Tocantins' }
  ]
}
export default Functions;
  