import React, { useState, useEffect, useContext } from 'react';
import logo from '../../assets/logo-home.png';
import './styles.css';
import '../../App.css';
import Api from '../../services/Api'
import Loading from '../../components/Loading';

function Cadastro({ history }) {
  const [category, setCategory] = useState("Prestador");
  const [msgErro, setMsgErro] = useState("");

  //Ao abrir a tela
  useEffect(() => {
    
  }, []);

  return (
  <>
    <div className="container v-center">
      <div id="cadastro" className="col-12 col-md-8 offset-md-2">
        <div className="text-center">
          <br/> <br/> <br/> <br/> <br/> <br/>
          <img id="logo" src={logo}/>
          <br/>
          <h3>Cadastro Efetuado!</h3>
          <br/>
          <p>Seu cadastro foi realizado com sucesso! Agora acesse o app e faça o login com as suas credenciais!</p>
        </div> 
        <div className="">
          <br /><br />
          <div className="row">
            <div className="col-md-6 col-12">
              <a href="https://play.google.com/store/apps/details?id=br.com.appviaki" target="_Blank" className="btn btn-default-outline col-12">
                <i className="fab fa-google-play fa-lg"> </i>
                <span style={{marginLeft: 5}}> Download para Android </span>
              </a>
            </div>
            <div className="col-md-6 col-12">
              <a href="https://apps.apple.com/br/app/viaki/id1567987443" target="_Blank" className="btn btn-default-outline col-12">
                <i className="fab fa-apple fa-lg"> </i>
                <span style={{marginLeft: 5}}> Download para iOS </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
</>);
}

export default Cadastro;
