//useEffect = Método chamado ao carragar a página;
import React, { useContext, useEffect, useState} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import ListItemBooking from '../../components/ListItemBooking'
import './styles.css';
import '../../App.css';
import UserContext from '../../contexts/UserContext';

export default function DashboardBookings({setLoading}) {
  const [bookings, setBookings] = useState([]);
  const [spaces, setSpaces] = useState([]);
  const {user, setUser} = useContext(UserContext);

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadBookings();
  }, []);

  async function loadBookings() {
    try {
      const response = await Api.get("/get-bookings-condominio/"+user[0].idCondominio._id);
      setBookings(response);
      loadSpaces();
      //setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function loadSpaces() {
    try {
      const response = await Api.get("/get-spaces-condominio/"+user[0].idCondominio._id);
      setSpaces(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }
  
  async function addBooking(){
    const bookingCopy = Array.from(bookings);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    bookingCopy.unshift({
      _id: bookingCopy.length +1+"",
      user: { _id: user[0]._id, name: user[0].name },
      space: { _id: "", space: "" },
      spaceName: "",
      observation: "",
      guests: [],
      status: "Agendado",
      dateStart: new Date().toISOString(),
      dateEnd: '', //new Date(new Date().substr(0,10) + " " + Functions.addTimes(new Date().substr(11,5), "01:00:00")).toISOString()
      condominio: user[0].idCondominio._id,
      active: true
    });
    await setBookings(bookingCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(bookingCopy.length)
  }

  async function removeBooking(id){
    setLoading(true);
    const bookingCopy = Array.from(bookings);
    var index = bookingCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.post('/delete-booking', {id: id});
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          bookingCopy.splice(index, 1);
          setBookings(bookingCopy);
        } else {
          if(response?.message){
            toast.error(response.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error("Falha ao realizar a operação!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    } else {
      bookingCopy.splice(index, 1);
      setBookings(bookingCopy);
    }
    setLoading(false);
  }

  return (
    <div id="bookingList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-4">
          <span className="title">Reservas de Espaços</span>
        </div>
        <div className="col-4 text-center">
        </div>
        <div className="col-4 text-right">
          <button type="button" onClick={addBooking} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
        </div>
      </div>
      <div className="col-12">
        <ul className="dashboardList">
          {bookings.length > 0 ?
          bookings.map(item => (
            <ListItemBooking
              key={item._id}
              id={item._id}
              spaces={spaces}
              space={item.space}
              user={item.user}
              guests={item?.guests ? item.guests : []}
              description={item.observation}
              dateStart={item.dateStart} 
              dateEnd={item.dateEnd} 
              status={item.status} 
              condominio={item.condominio} 
              active={item.active} 
              onDelete={() => removeBooking(item._id)}
              loadItem={() => loadBookings()}
              setLoading={setLoading}
              //ref={childRef}
            />
          )) :
          <div className="col-12 text-center">
            <br /><br /><br />
            <span className="labelFilter">Não há reservas solicitadas!</span>
          </div>}
        </ul>
      </div>
    </div>
  )
 
}