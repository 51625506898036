import React, { useState, useMemo, useEffect } from 'react';
import InputMask from "react-input-mask";
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';

const ListItemNotice = ({ id, user, guest, rg, description, date, condominio, status, active, onDelete, loadItem, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [stateName, setStateName] = useState(user.name);
  const [stateGuest, setStateGuest] = useState(guest);
  const [stateRG, setStateRG] = useState(rg);
  const [stateDescription, setStateDescription] = useState(description);
  const [stateNoticeDate, setStateNoticeDate] = useState(date.substr(0,10));
  const [stateNoticeHour, setStateNoticeHour] = useState(date.substr(11,5));
  const [stateStatus, setStateStatus] = useState(status);
  const [stateActive, setStateActive] = useState(active);

  async function handleSubmit(e){
    e.preventDefault();
    if(stateName !== "" && stateNoticeHour !== "" && stateNoticeDate !== ""){
      //console.log(new Date(stateNoticeDate+" "+stateNoticeHour));
      //return false;
      setLoading(true);
      //Cria um estrutura Multiform Data;
      const data = {
        id: id,
        condominio: condominio,
        user: user._id,
        rg: stateRG,
        name: stateGuest.trim(),
        status: stateStatus.trim(),
        date: new Date(new Date(stateNoticeDate+" "+stateNoticeHour).getTime() - (180 * 60000)).toISOString(),
        observation: stateDescription.trim(),
      }
      try {
        const response = await Api.post("/store-notice", data);
        console.log(response);
        if(response.hasOwnProperty('_id') || response?.ok){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setToggleItem([]);
          const reloadItem = loadItem;
          reloadItem();
          setLoading(false);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("(*) Preencha todos os campos obrigatórios!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
  
  return (
      <li className={toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList"} style={active || stateActive ? {} : {opacity: 0.4}}>
        <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
          <div className="col-10">
            <span>{stateNoticeDate.split("-").reverse().join("/")} às {stateNoticeHour}h - {stateGuest}</span>
          </div>
          <div className="col-2 text-right">
            <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItem[0] === id ?
          <div className="row">
            <div className="col-12">
              <hr/>
              <form onSubmit={handleSubmit}>
                <div className="row v-center">
                  <div className="col-12">
                    {/*<label className="switch itemDashboardListSwitch">
                      <input type="checkbox" value={stateActive} checked={stateActive} onChange={() => setStateActive(!stateActive)} />
                      <div className="slider"></div>
                    </label>*/}
                    <div className="row">
                      <div className="form-group col-12">
                        <label className="labelInput">Morador: *</label>
                        <input 
                          disabled="disabled"
                          type="text" 
                          className="form-control" 
                          placeholder="Nome do Morador"
                          value={stateName}
                          onChange={event => setStateName(event.target.value)}
                        />
                      </div>
                      <div className="form-group col-6">
                        <label className="labelInput">Convidado: *</label>
                        <input 
                          disabled="disabled"
                          type="text" 
                          className="form-control" 
                          placeholder="Nome do Convidado"
                          value={stateGuest}
                          onChange={event => setStateGuest(event.target.value)}
                        />
                      </div>
                      <div className="form-group col-6">
                        <label className="labelInput">RG: </label>
                        <input 
                          disabled="disabled"
                          type="text" 
                          className="form-control" 
                          placeholder="RG do Convidado"
                          value={stateRG}
                          onChange={event => setStateRG(event.target.value)}
                        />
                      </div>
                      <div className="form-group col-6">
                        <label className="labelInput">Data da Entrada: *</label>
                        <input 
                          disabled="disabled"
                          type="date" 
                          className="form-control" 
                          placeholder="Data de reserva"
                          value={stateNoticeDate}
                          onChange={event => setStateNoticeDate(event.target.value)}
                        />
                      </div>
                      <div className="form-group col-6">
                        <label className="labelInput">Hora da Entrada: *</label>
                        <input 
                          disabled="disabled"
                          type="time" 
                          className="form-control" 
                          placeholder="Hora de reserva"
                          value={stateNoticeHour}
                          onChange={event => setStateNoticeHour(event.target.value)}
                        />
                      </div>
                      <div className="form-group col-12">
                        <label className="labelInput">Observação: </label>
                        <textarea 
                          disabled="disabled"
                          className="form-control" 
                          placeholder=""
                          value={stateDescription} 
                          onChange={event => setStateDescription(event.target.value)} 
                          />
                      </div>
                    </div>     
                  </div>     
                  {/*<div className="col-12 text-right">
                    <button type="button" className="btn btn-delete" onClick={onDelete}> Excluir </button>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <button type="submit" className="btn btn-default"> Salvar </button>
                  </div>*/}
                </div>
              </form>
                
            </div>
          </div>
          : '' }
      </li>
  );
};

export default ListItemNotice;
