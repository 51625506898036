//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import Functions from '../../components/Functions'
import ListItemUser from '../../components/ListItemUser'
import './styles.css';
import '../../App.css';

export default function DashboardPrestadores({setLoading}) {
  const [prestadores, setPrestadores] = useState([]);
  const [cities, setCities] = useState([]);
  const [categorias, setCategorias] = useState([]);


 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadPrestadores();
  }, []);

  async function loadPrestadores() {
    try {
      const response = await Api.getPrestadores();
      setPrestadores(response);
      //Filtra as Categorias
      const arrCategorias = getCategorias(response);
      console.log(arrCategorias);
      setCategorias(arrCategorias);
      //Filtra as Cidades
      const arrCities = getCities(response);
      console.log(arrCities);
      setCities(arrCities);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  function getCategorias(data){
    let categoria = "", arrCategorias = [];
    for(let i = 0; i < data.length; i++){
      if(categoria !== data[i].servico){
        arrCategorias.push(data[i].servico)
      }
      categoria = data[i].servico;
    }
    console.log(arrCategorias);
    return arrCategorias;
  }

  function getCities(data){
    let city = "", arrCities = [];
    for(let i = 0; i < data.length; i++){
      if(city !== data[i].city){
        arrCities.push(data[i].city)
      }
      city = data[i].city;
    }
    console.log(arrCities);
    return arrCities;
  }

  async function addPrestador(){
    const prestadorCopy = Array.from(prestadores);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    prestadorCopy.unshift({
      _id: prestadorCopy.length +1,
      item: "Prestador",
      name: "",
      description: "",
      //thumbnail: "",
      city: "",
      state: "",
      address: "",
      latitude: "",
      longitude: "",
      phone: "",
      email: "",
      whatsapp: "",
      instagram: "", 
      facebook: "",
      website: "", 
      order: 0,
      active: true
    });
    await setPrestadores(prestadorCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(prestadorCopy.length)
  }

  async function removePrestador(id){
    setLoading(true);
    const prestadorCopy = Array.from(prestadores);
    var index = prestadorCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.deletePrestador(id);
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          prestadorCopy.splice(index, 1);
          setPrestadores(prestadorCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      prestadorCopy.splice(index, 1);
      setPrestadores(prestadorCopy);
    }
    setLoading(false);
  }

  //console.log(prestadores);
  //const childRef = useRef();
  return (
    <div id="prestadorList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-8">
          <span className="title">Prestadores de Serviço</span>
        </div>
        <div className="col-4 text-right">
          {/*<button type="button" onClick={addPrestador} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>*/}
        </div>
      </div>
        <div className="col-12">
          <ul className="userList">
            {
            prestadores.length > 0 ?
            prestadores.map(item => (
              <ListItemUser
                key={item._id}
                item="Prestador"
                id={item._id}
                name={item.name}
                thumbnail={item.hasOwnProperty("thumbnail") && item.thumbnail ? item.thumbnail : item.idServico.thumbnail}
                city={item.city} 
                state={item.state}
                email={item.email}
                phone={item.phone} 
                whatsapp={item.whatsapp}
                servico={item.idServico}
                plan={item.idPlan}
                premium={item.premium}
                datePayment={item.datePayment} 
                dateValidPremium={item.dateValidPremium}
                dateCreated={item.dateCreated}
                //codeConfirm={item.codeConfirm} 
                //accountConfirm={item.accountConfirm} 
                order={item.order} 
                admin={item.admin}
                active={item.active}
                onDelete={() => removePrestador(item._id)}
                loadUsers={() => loadPrestadores()}
                setLoading={setLoading}
                //cities={cities}
                //description={item.description}
                //servico={item.servico}
                 
                //address={item.address} 
                //latitude={item.latitude} 
                //longitude={item.longitude} 
                //instagram={item.instagram} 
                //facebook={item.facebook} 
                //website={item.website} 
              />
            )) :
            <div className="col-12 text-center">
              <span className="labelFilter">Não há lojas cadastrados!</span>
            </div>
          }
          </ul>
      </div>
    </div>
  )
 
}