//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState} from 'react';
import logo from '../../assets/logo-home.png';
import homeSmartphone from '../../assets/iphone-home.png';
import Api from '../../services/Api'
import Loading from '../../components/Loading';
import './styles.css';
import '../../App.css';

export default function Dashboard({ history }) {
  const [loading, setLoading] = useState(true);
  const [userLogado, setUserLogado] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [listMenuActive, setListMenuActive] = useState(0);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    optionMenu(listMenuActive);
  }, [listMenuActive]);


  function optionMenu(menu){
    if(menu === 1) {
      setListMenuActive(1);
    } else if(menu === 2) {
      setListMenuActive(2);
    } else if(menu === 3) {
      setListMenuActive(3);
    } else if(menu === 5) {
      setListMenuActive(5);
    } else if(menu === 4) {
      //logout();
    } else {
      setListMenuActive(0);
    }
    setToggleMenu(false);
  }
  
  return (
    <div id="homeIndex">
      <div className="containers">
        {/*<div className="row navbar">
          <div className="col-3 text-center">
            <a href="#" target="_Blank">
              <span> Sobre </span>
            </a>
          </div>
          <div className="col-3 text-center">
            <a href="#" target="_Blank">
              <span> Envie um Presente </span>
            </a>
          </div>
          <div className="col-3 text-center">
            <a href="#" target="_Blank">
              <span> Ative seu Cupom </span>
            </a>
          </div>
          <div className="col-3 text-center">
            <a href="http://numinousapp.com/app/" target="_Blank">
              <span> Login </span>
            </a>
          </div>
        </div>*/}
        <div className="bodyIndex v-center">
          <div className="container">
            <div className="row v-center">
              <div className="col-md-7 col-12 text-center logo">
                <div className="logo">
                  <img src={logo} width="100%"/>
                </div>
                <br /><br />
                <h5>Conectamos moradores de condomínios à prestadores de serviços! Junte-se à nós!</h5>
                <div className="hidden-xs">
                  <br /><br /><br />
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <a href="https://play.google.com/store/apps/details?id=br.com.appviaki" target="_Blank" className="btn btn-default-outline col-12">
                        <i className="fab fa-google-play fa-lg"> </i>
                        <span style={{marginLeft: 5}}> Download para Android </span>
                      </a>
                    </div>
                    <div className="col-md-6 col-12">
                      <a href="https://apps.apple.com/br/app/viaki/id1567987443" target="_Blank" className="btn btn-default-outline col-12">
                        <i className="fab fa-apple fa-lg"> </i>
                        <span style={{marginLeft: 5}}> Download para iOS </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-12 text-center">
                <div className="homeSmartphone">
                  <img src={homeSmartphone} width="100%"/>
                </div>
              </div>
              <div className="col-12 text-center">
                <div className="visible-xs">
                  <br /><br />
                  <div className="col-md-6 col-12">
                    <a href="https://play.google.com/store/apps/details?id=br.com.appviaki" target="_Blank" className="btn btn-default-outline col-10">
                      <i className="fab fa-google-play fa-lg"> </i>
                      <span style={{marginLeft: 5}}> Download para Android </span>
                    </a>
                  </div>
                  <div className="col-md-6 col-12">
                    <a href="https://play.google.com/store/apps/details?id=br.com.appviaki" target="_Blank" className="btn btn-default-outline col-10">
                      <i className="fab fa-apple fa-lg"> </i>
                      <span style={{marginLeft: 5}}> Download para iOS </span>
                    </a>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="footerIndex">

        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-6 col-md-5 text-left">
              <a href="http://numinousapp.com/politica-de-privacidade.pdf" target="_Blank">
                <p> Política de Privacidade </p>
              </a>
              <a href="#" target="_Blank">
                <p> Termos e Condições </p>
              </a>
            </div>
            <div className="col-md-1"></div>
            <div className="col-6 col-md-5 text-left">
              <a href="#" target="_Blank">
                <p> Perguntas Frequentes </p>
              </a>
              <a href="#" target="_Blank">
                <p> Contato </p>
              </a>
            </div>
          </div>
        </div>

      </div>*/}

      {/*<div className="container-fluid">
        <div className="row">
          <div className="col-12 barraSuperior">
              <div className="row v-center">
                <div className="col-4 text-left logo">
                  <img id="logo-barra" src={logo} alt="Numinous" width="100%"/>
                </div>
                <div className="col-4 text-center">
                  <span id="titleMenu"> 
                    {listMenuActive === 0 ? 'Home' : ''}
                    {listMenuActive === 1 ? 'Virtudes' : ''}
                    {listMenuActive === 2 ? 'Músicas' : ''}
                    {listMenuActive === 5 ? 'Usuários' : ''}
                    {listMenuActive === 3 ? 'Notificações' : ''}
                  </span>
                </div>
                <div className="col-4 text-right imgMenu">
                  <span className="fa fa-bars fa-2x"id="btnMenu" onClick={() => toggleMenu ? setToggleMenu(false) : setToggleMenu(true)} alt="Menu"></span>
                </div>
              </div>
          </div>
        </div>
        <ul id="menuUser" className={`${toggleMenu ? 'active' : ''}`}>
          <li onClick={() => setListMenuActive(0)} className={`${listMenuActive === 0 ? 'active' : ''}`}><span className="fa fa-home fa-lg"></span> Home</li>
          <li onClick={() => setListMenuActive(1)} className={`${listMenuActive === 1 ? 'active' : ''}`}><span className="fa fa-volume-up fa-lg"></span> Virtudes</li>
          <li onClick={() => setListMenuActive(2)} className={`${listMenuActive === 2 ? 'active' : ''}`}><span className="fas fa-music fa-lg"></span> Músicas</li>
          <li onClick={() => setListMenuActive(5)} className={`${listMenuActive === 5 ? 'active' : ''}`}><span className="fa fa-users fa-lg"></span> Usuários </li>
          <li onClick={() => setListMenuActive(3)} className={`${listMenuActive === 3 ? 'active' : ''}`}><span className="fa fa-bell fa-lg"></span> Notificações </li>
          <li onClick={() => setListMenuActive(4)} className={`${listMenuActive === 4 ? 'active' : ''}`}><span className="fa fa-sign-out-alt fa-lg"></span> Sair</li>
        </ul>
        <Loading visible={loading}/>
    </div>*/}
    </div>
  );
 
}