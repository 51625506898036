import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Login from './pages/Login';
import Cadastro from './pages/Cadastro';
import CadastroEfetuado from './pages/CadastroEfetuado';
import Dashboard from './pages/Dashboard';
import Index from './pages/Index';

export default function Routes() {
  return (
    <BrowserRouter>
      {/*Switch: Apenas uma rota é executada por vez */}
      {/* exact: rota exata */}
      <Switch>
        <Route path="/" exact component={Index} />
        <Route path="/painel-admin" exact component={Login} />
        <Route path="/painel-admin/login" exact component={Login} />
        <Route path="/painel-admin/dashboard" component={Dashboard} />
        <Route path="/cadastro-prestador" exact component={Cadastro} />
        <Route path="/cadastro-efetuado" exact component={CadastroEfetuado} />
      </Switch>
    </BrowserRouter>
  );
}