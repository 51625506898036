//useEffect = Método chamado ao carragar a página;
import React, { useState, useContext } from 'react';
import { GiBullseye } from "react-icons/gi";
import { FiShoppingBag } from "react-icons/fi";
import { FaUsers, FaUsersCog, FaBullhorn, FaBuilding, FaStoreAlt } from "react-icons/fa";
//import { Link } from 'react-router-dom';
//import Api from '../../services/api'
import UserContext from '../../contexts/UserContext';
import ListItem from '../../components/ListItem';
import './styles.css';
import Api from '../../services/Api';
import iconProfileWhite from '../../assets/icons/icon-profile-white.png';

export default function DashboardHome(props) {
  const {user, setUser} = useContext(UserContext);
  const [editCondominio, setEditCondominio] = useState(false);

  return (
    <div className="bodyDashboard">
      <div className="itensHome v-center">
        <div className="col-12">
          <br />
          <div className="v-center"> 
            <label id="imgUser" style={user[0].hasOwnProperty("thumbnail") ? { backgroundImage: `url(${user[0].thumbnail})` } : { backgroundImage: `url(${iconProfileWhite}`}}></label>
            <div style={{marginLeft: 15, paddingTop: 12, paddingBottom: 12}}>
              <div><b style={{fontSize: 20}}>{user[0].name}</b></div>
              <div>{user[0].email}</div>
            <button type="button" onClick={() => props.setModalPassword(!props.modalPassword)} className="btnLink">Alterar Senha?</button>
            </div>
          </div>
          <br />
        </div>
        {/*<h3 className="title">Menus</h3>*/}
        {user[0].hasOwnProperty("admin") && user[0].admin ? <>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => props.callbackParent(1)} >
              <FaBuilding size="5em" className="itemHomeIcon"/>
              <div className="title">Condomínios</div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => props.callbackParent(2)}>
              <FaUsers size="5em" className="itemHomeIcon"/>
              <div className="title">Moradores</div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => props.callbackParent(5)}>
              <FaUsersCog size="5em" className="itemHomeIcon"/>
              <div className="title">Prestadores</div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => props.callbackParent(7)}>
              <FaStoreAlt size="5em" className="itemHomeIcon"/>
              <div className="title">Comércios Locais</div>
            </div>
          </div>
          {/*<div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => props.callbackParent(6)}>
              <FaBullhorn size="5em" className="itemHomeIcon"/>
              <div className="title">Banners</div>
            </div>
          </div>
          <h3 className="title">Apps</h3>
          <div className="col-12 col-md-6 text-center">
            <a href="https://play.google.com/store" target="_Blank">
              <div className="itemHome">
                <span className="fab fa-android fa-4x"></span>
                <div className="title">Android</div>
              </div>
            </a>
          </div>
          <div className="col-12 col-md-6 text-center">
            <a href="https://www.apple.com/br/" target="_Blank">
              <div className="itemHome">
                  <span className="fab fa-apple fa-4x"></span>
                  <div className="title">iOS</div>
              </div>
            </a>
          </div>*/}
        </> : <>
          <div className="col-12">
            <div className="itemHome" style={{padding: 0, cursor: "initial"}}>
              {editCondominio ?
                <div className="col-12">
                  <ul className="dashboardLists"> 
                    <ListItem
                      key={user[0].idCondominio._id}
                      item="editCondominio"
                      id={user[0].idCondominio._id}
                      name={user[0].idCondominio.name}
                      description={user[0].idCondominio.description}
                      thumbnail={user[0].idCondominio.thumbnail} 
                      //cities={cities}
                      city={user[0].idCondominio.city} 
                      state={user[0].idCondominio.state} 
                      address={user[0].idCondominio.address} 
                      latitude={user[0].idCondominio.latitude} 
                      longitude={user[0].idCondominio.longitude} 
                      phone={user[0].idCondominio.phone} 
                      email={user[0].idCondominio.email} 
                      whatsapp={user[0].idCondominio.whatsapp} 
                      instagram={user[0].idCondominio.instagram} 
                      facebook={user[0].idCondominio.facebook} 
                      website={user[0].idCondominio.website} 
                      linkSchedule={user[0].idCondominio.linkSchedule} 
                      order={user[0].idCondominio.order} 
                      active={user[0].idCondominio.active} 
                      //onDelete={() => removeCondominio(user[0].idCondominio._id)}
                      setEditCondominio={(value) => setEditCondominio(value)}
                      loadItem={() => props.reloadUser()}
                      setLoading={props.setLoading}
                      //ref={childRef}
                    />
                  </ul>
                </div>
              : <>
                <div className="v-center"> 
                  <label id="imgCondominio" style={{ backgroundImage: `url(${user[0].idCondominio.thumbnail})` }}></label>
                  <div style={{marginLeft: 15, paddingTop: 12, paddingBottom: 12}}>
                    <div><b style={{fontSize: 20}}>{user[0].idCondominio.name}</b></div>
                    <div>{user[0].idCondominio.city} ({user[0].idCondominio.state})</div>
                    <button type="button" onClick={() => setEditCondominio(!editCondominio)} className="btn btn-default" style={{marginTop: 20}}>Editar Condomínio</button>
                    {/*<button type="button" onClick={() => props.callbackParent(2)} className="btn btn-default" style={{marginTop: 20}}>Gerenciar Moradores</button>*/}
                  </div>
                </div>
              </>}
            </div>
          </div>
        </> }
      </div>
    </div>
  )
 
}