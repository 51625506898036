import React, { useState, useEffect, useContext } from 'react';
import logo from '../../assets/logo-home.png';
import './styles.css';
import '../../App.css';
import Api from '../../services/Api'
import UserContext from '../../contexts/UserContext';

function Login({ history }) {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [msgErro, setMsgErro] = useState(false);
  const {user, setUser} = useContext(UserContext);

  useEffect(() => {
    const user_id = sessionStorage.getItem('user');
    if(user_id) {
      validaLogin(this, user_id);
    }
  }, []);

  async function validaLogin(event, user_id) {
    if(user_id){
      const response = await Api.getUser(user_id);
      console.log(response)
      if(response !== null){
        if((response.hasOwnProperty("admin") && response.admin) || (response.hasOwnProperty("adminCondominio") && response.adminCondominio)){
          sessionStorage.setItem('user', response._id);
          history.push('/painel-admin/dashboard');
          setUser([response]);
        }
      }
    } else {
      event.preventDefault();
      const response = await Api.login(email, senha);
      if(response !== null){
        if((response.hasOwnProperty("admin") && response.admin) || (response.hasOwnProperty("adminCondominio") && response.adminCondominio)){
          sessionStorage.setItem('user', response._id);
          history.push('/painel-admin/dashboard');
          setUser([response]);
        } else {
          setMsgErro(true);  
          setUser([]);
        }
      } else {
        setMsgErro(true);  
        setUser([]);  
      }
    }
  }
  return (
    <div className="container">
      <div className="login">
        <img id="logo" src={logo}/>
        <div className="content">
          <div className="text-center">
            <h1>Login</h1>
            <p>Informe suas credenciais para entrar!</p>
          </div> 
          <br/>
          <form onSubmit={validaLogin}>
            <div className="form-group col-12">
              <label className="labelInput">E-mail:</label>
              <input 
                type="email" 
                className="form-control" 
                name="email" 
                id="email" 
                placeholder="Seu e-mail"
                value={email}
                onChange={event => setEmail(event.target.value)}/>
            </div>            
            <div className="form-group col-12">
              <label className="labelInput">Senha:</label>
              <input 
                type="password"  
                className="form-control" 
                name="senha" id="senha" 
                placeholder="Sua senha"
                value={senha}                
                onChange={event => setSenha(event.target.value)}/>
            </div>
            <p className={`col-12 alert alert-danger ${msgErro === false ? 'hidden' : ''}`} onClick={() => setMsgErro(false)}>Falha ao logar com as credencias informadas!</p>
            <button type="submit" className="btn btn-default col-12">Entrar</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
