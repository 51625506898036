//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import Functions from '../../components/Functions'
import ListItemBusiness from '../../components/ListItemBusiness'
import './styles.css';
import '../../App.css';

export default function DashboardBusiness({setLoading}) {
  const [businesses, setBusinesses] = useState([]);
  const [cities, setCities] = useState([]);
  const [estados, setEstados] = useState([]);
  const [categories, setCategories] = useState([]);
  const [condominios, setCondominios] = useState([]);

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadBusinesses();
  }, []);

  async function loadBusinesses() {
    try {
      const response = await Api.getBusinesses();
      setBusinesses(response);
      await loadCategories();
    } catch (error) {
      console.log(error)
    }
  }

  async function loadCategories() {
    try {
      const response = await Api.getCategories("Comércios Locais");
      setCategories(response);
      await loadCondominios();
    } catch (error) {
      console.log(error)
    }
  }

  async function loadCondominios() {
    try {
      const response = await Api.getCondominios();
      setCondominios(response);
      const arrCities = getCities(response);
      //console.log(arrCities);
      setCities(arrCities);
      const arrEstados = getEstados(response);
      //console.log(arrEstados);
      setEstados(arrEstados);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  function getCities(data){
    let city = "", arrCities = [];
    for(let i = 0; i < data.length; i++){
      if(city !== data[i].city){
        arrCities.push(data[i].city)
      }
      city = data[i].city;
    }
    console.log(arrCities);
    return arrCities;
  }

  function getEstados(data){
    let state = "", arr = [];
    for(let i = 0; i < data.length; i++){
      if(state !== data[i].state){
        arr.push(data[i].state)
      }
      state = data[i].state;
    }
    console.log(arr);
    return arr;
  }

  async function addBusiness(){
    const businessCopy = Array.from(businesses);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    businessCopy.unshift({
      _id: businessCopy.length +1,
      item: "Business",
      name: "",
      description: "",
      //thumbnail: "",
      category: {},
      condominios: [],
      city: "",
      state: "",
      address: "",
      latitude: "",
      longitude: "",
      phone: "",
      email: "",
      whatsapp: "",
      instagram: "", 
      facebook: "",
      website: "", 
      linkSchedule: "", 
      order: 0,
      active: true
    });
    await setBusinesses(businessCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(businessCopy.length)
  }

  async function removeBusiness(id){
    setLoading(true);
    const businessCopy = Array.from(businesses);
    var index = businessCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.api.post('/delete-business', {'id': id});
        console.log(response);
        if(Number(response.data.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          businessCopy.splice(index, 1);
          setBusinesses(businessCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      businessCopy.splice(index, 1);
      setBusinesses(businessCopy);
    }
    setLoading(false);
  }

  return (
    <div id="businessList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-4">
          <span className="title">Comércios Locais</span>
        </div>
        <div className="col-4 text-center">
        </div>
        <div className="col-4 text-right">
          <button type="button" onClick={addBusiness} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
        </div>
      </div>
        <div className="col-12">
          <ul className="dashboardList">
            {
            businesses.length > 0 ?
            businesses.map(item => (
              <ListItemBusiness
                key={item._id}
                item="Business"
                id={item._id}
                name={item.name}
                description={item.description}
                thumbnail={item.thumbnail} 
                category={item.category}
                categories={categories}
                condominiosAtendidos={item.condominios}
                listaCondominios={condominios}
                estados={estados}
                cities={cities}
                city={item.city} 
                state={item.state} 
                address={item?.address ? item.address : ""} 
                latitude={item?.latitude ? item.latitude : ""} 
                longitude={item?.longitude ? item.longitude : ""} 
                phone={item?.phone ? item.phone : ""} 
                whatsapp={item?.whatsapp ? item.whatsapp : ""} 
                email={item?.email ? item.email : ""} 
                //instagram={item.instagram} 
                //facebook={item.facebook} 
                //website={item.website} 
                order={item.order} 
                active={item.active} 
                onDelete={() => removeBusiness(item._id)}
                loadItem={() => loadBusinesses()}
                setLoading={setLoading}
                //ref={childRef}
              />
            )) :
            <div className="col-12 text-center">
              <span className="labelFilter">Não há comércios cadastrados!</span>
            </div>
          }
          </ul>
      </div>
    </div>
  )
}