//useEffect = Método chamado ao carragar a página;
import React, { useContext, useEffect, useState} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import ListItemSpace from '../../components/ListItemSpace'
import './styles.css';
import '../../App.css';
import UserContext from '../../contexts/UserContext';

export default function DashboardSpaces({setLoading}) {
  const [spaces, setSpaces] = useState([]);
  const {user, setUser} = useContext(UserContext);

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadSpaces();
  }, []);

  async function loadSpaces() {
    try {
      const response = await Api.get("/get-spaces-condominio/"+user[0].idCondominio._id);
      setSpaces(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }
  
  async function addSpace(){
    const spaceCopy = Array.from(spaces);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    spaceCopy.unshift({
      _id: spaceCopy.length +1,
      name: "",
      description: "",
      timeBooking: "01:00:00",
      condominio: user[0].idCondominio._id,
      //thumbnail: "",
      active: true
    });
    await setSpaces(spaceCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(spaceCopy.length)
  }

  async function removeSpace(id){
    setLoading(true);
    const spaceCopy = Array.from(spaces);
    var index = spaceCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.post('/delete-space', {id: id});
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          spaceCopy.splice(index, 1);
          setSpaces(spaceCopy);
        } else {
          if(response?.message){
            toast.error(response.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error("Falha ao realizar a operação!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    } else {
      spaceCopy.splice(index, 1);
      setSpaces(spaceCopy);
    }
    setLoading(false);
  }

  return (
    <div id="spaceList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-8">
          <span className="title">Espaços Sociais</span>
        </div>
        <div className="col-4 text-right">
          <button type="button" onClick={addSpace} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
        </div>
      </div>
        <div className="col-12">
          <ul className="dashboardList">
            {spaces.length > 0 ?
            spaces.map(item => (
              <ListItemSpace
                key={item._id}
                id={item._id}
                name={item.name}
                description={item.description}
                //thumbnail={item.thumbnail} 
                timeBooking={item.timeBooking} 
                preBooking={item.preBooking}
                maxCapacity={item.maxCapacity} 
                condominio={item.condominio} 
                active={item.active} 
                onDelete={() => removeSpace(item._id)}
                loadItem={() => loadSpaces()}
                setLoading={setLoading}
                //ref={childRef}
              />
            )) :
            <div className="col-12 text-center">
              <br />
              <br />
              <br />
              <span className="labelFilter">Não há espaços cadastrados!</span>
            </div>}
          </ul>
      </div>
    </div>
  )
 
}