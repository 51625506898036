//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import Functions from '../../components/Functions'
import ListItem from '../../components/ListItem'
import './styles.css';
import '../../App.css';

export default function DashboardCondominios({setLoading}) {
  const [condominios, setCondominios] = useState([]);
  const [cities, setCities] = useState([]);
  const [dateFilter, setDateFilter] = useState('');
  const [monthFilter, setMonthFilter] = useState('');

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadCondominios();
  }, []);

  /*async function loadDataAtual(){
    var dataHoraAtual = await Api.loadDataAtual();
    var date = new Date(dataHoraAtual);
    var dateStart = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substr(0,10);
    var dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().substr(0,10);
    console.log(dateStart)
    console.log(dateEnd)
    loadCondominios(dateStart, dateEnd);
    setMonthFilter(Functions.checkMonth(date.toISOString().substr(5,2)) + " de " + date.toISOString().substr(0,4));
    setDateFilter(dataHoraAtual);
  }*/

  async function loadCondominios() {
    try {
      const response = await Api.getCondominios();
      setCondominios(response);
      const arrCities = getCities(response);
      console.log(arrCities);
      setCities(arrCities);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  function getCities(data){
    let city = "", arrCities = [];
    for(let i = 0; i < data.length; i++){
      if(city !== data[i].city){
        arrCities.push(data[i].city)
      }
      city = data[i].city;
    }
    console.log(arrCities);
    return arrCities;
  }

  /*function changeMonth(action){
    var dateStart, dateEnd;
    var dataAtual = new Date(dateFilter);
    if(action === "back"){
      dateStart = new Date(dataAtual.getFullYear(), dataAtual.getMonth() -1, 1).toISOString().substr(0,10);
      dateEnd = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 0).toISOString().substr(0,10);
    } else {
      dateStart = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 1).toISOString().substr(0,10);
      dateEnd = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 2, 0).toISOString().substr(0,10);
    }
    loadCondominios(dateStart, dateEnd);
    setDateFilter(dateEnd);
    setMonthFilter(Functions.checkMonth(dateEnd.substr(5,2)) + " de " + dateEnd.substr(0,4));
    console.log(dateStart);
    console.log(dateEnd);
  }*/

  async function addCondominio(){
    const condominioCopy = Array.from(condominios);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    condominioCopy.unshift({
      _id: condominioCopy.length +1,
      item: "Condominio",
      name: "",
      description: "",
      //thumbnail: "",
      city: "",
      state: "",
      address: "",
      latitude: "",
      longitude: "",
      phone: "",
      email: "",
      whatsapp: "",
      instagram: "", 
      facebook: "",
      website: "", 
      linkSchedule: "", 
      order: 0,
      active: true
    });
    await setCondominios(condominioCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(condominioCopy.length)
  }

  async function removeCondominio(id){
    setLoading(true);
    const condominioCopy = Array.from(condominios);
    var index = condominioCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.api.post('/delete-condominio', {'id': id});
        console.log(response);
        if(Number(response.data.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          condominioCopy.splice(index, 1);
          setCondominios(condominioCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      condominioCopy.splice(index, 1);
      setCondominios(condominioCopy);
    }
    setLoading(false);
  }

  //console.log(condominios);
  //const childRef = useRef();
  return (
    <div id="condominioList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-4">
          <span className="title">Condomínios</span>
        </div>
        <div className="col-4 text-center">
            {/*<span onClick={() => changeMonth("back")}>
              <span className="fas fa-chevron-circle-left fa-lg iconFilter"></span>
            </span>
            <span className="labelFilter">{monthFilter}</span>
            <span onClick={() => changeMonth("next")}>
              <span className="fas fa-chevron-circle-right fa-lg iconFilter"></span>
            </span>*/}
        </div>
        <div className="col-4 text-right">
          <button type="button" onClick={addCondominio} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
        </div>
      </div>
        <div className="col-12">
          <ul className="dashboardList">
            {
            condominios.length > 0 ?
            condominios.map(item => (
              <ListItem
                key={item._id}
                item="Condominio"
                id={item._id}
                name={item.name}
                description={item.description}
                thumbnail={item.thumbnail} 
                cities={cities}
                city={item.city} 
                state={item.state} 
                address={item.address} 
                latitude={item.latitude} 
                longitude={item.longitude} 
                phone={item.phone} 
                email={item.email} 
                whatsapp={item.whatsapp} 
                instagram={item.instagram} 
                facebook={item.facebook} 
                website={item.website} 
                linkSchedule={item.linkSchedule} 
                order={item.order} 
                active={item.active} 
                onDelete={() => removeCondominio(item._id)}
                loadItem={() => loadCondominios()}
                setLoading={setLoading}
                //ref={childRef}
              />
            )) :
            <div className="col-12 text-center">
              <span className="labelFilter">Não há condomínios cadastrados!</span>
            </div>
          }
          </ul>
      </div>
    </div>
  )
 
}