import React, { useState, useEffect, useContext } from 'react';
import logo from '../../assets/logo-home.png';
import './styles.css';
import '../../App.css';
import Api from '../../services/Api'
import Loading from '../../components/Loading';
import Functions from '../../components/Functions';
import UserContext from '../../contexts/UserContext';

function Cadastro({ history }) {
  const [loading, setLoading] = useState(true);
  const [subTitle, setSubTitle] = useState('Junte-se à nós e ofereça seus serviços em nossos condomínios parceiros!');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState('');
  const [states, setStates] = useState([]);
  const [state, setState] = useState('');
  const [pass, setPass] = useState('');
  const [passConfirm, setPassConfirm] = useState('');
  const [servicos, setServicos] = useState([]);
  const [servicoSelected, setServicoSelected] = useState('');
  const [category, setCategory] = useState("Prestador");
  const [msgErro, setMsgErro] = useState("");
  const {user, setUser} = useContext(UserContext);

  //Ao abrir a tela
  useEffect(() => {
    if(category === "Prestador"){
      setSubTitle("Junte-se à nós e ofereça seus serviços em nossos condomínios parceiros!");
      loadServicos("Serviços");
    } else {
      setSubTitle("Junte-se à nós e ofereça seus produtos em nossos condomínios parceiros!");
      loadServicos("Comércios Locais");
    }
    loadCondominios();
  }, []);

  async function loadServicos(category) {
    setLoading(true);
    var response= await Api.getCategories(category);
    console.log(response);
    if(response.length > 0) {
      setServicos(response);
    } else {
      setServicos([]);
    }
    setLoading(false);
  }

  async function loadCondominios() {
    try {
      const response = await Api.getCondominios();
      var arrStates= getStates(response);
      var arrCities = getCities(response);
      console.log(arrStates);
      console.log(arrCities);
      setStates(arrStates);
      setCities(arrCities);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  function getCities(data){
    let city = "", arrCities = [];
    for(let i = 0; i < data.length; i++){
      if(city !== data[i].city){
        arrCities.push({_id: i+"", name: data[i].city, city: data[i].city, state: data[i].state});
      }
      city = data[i].city;
    }
    //console.log(arrCities);
    return arrCities;
  }

  function getStates(data){
    let state = "", arr = [];
    for(let i = 0; i < data.length; i++){
      if(state !== data[i].state){
        arr.push({_id: i+"", state: data[i].state});
      }
      state = data[i].state;
    }
    //console.log(arr);
    return arr;
  }
  
  function validateEmail(mail) {
    if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
      return (true)
    } else{
      return (false)
    }
  }
  
  async function handleSubmit(event) {
    event.preventDefault();
    setMsgErro("");
    if(name !== '' && email !== '' && pass !== '' && passConfirm !== '' && state !== '' && city !== '' && servicoSelected !== ''){
      if(validateEmail(email)){
        if(pass.length >= 6){
          if(pass === passConfirm){
            setLoading(true);
            var data = {
              "name": name,
              "email": email,
              "city": city,
              "state": state,
              "password": pass,
              "idServico": servicoSelected,
              "category": servicoSelected
            };
            var route = "";
            if(category === "Prestador") {
              route = "/store-prestador";
            } else {
              route = "/store-business";
            }
            var response = await Api.post(route, data);
            if(response){
              console.log(response);
              history.push('/cadastro-efetuado');
              /*toast.success("Seu cadastro foi realizado com sucesso!\nAcesse o app e faça o login!", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });*/
            } else {
              setMsgErro("Falha ao realizar o cadastro! Tente novamente em breve!");
            }
            setTimeout(() => {
              setLoading(false);
            }, 500);
          } else {
            setMsgErro("As senhas não conferem!");
          }
        } else {
          setMsgErro("A senha deve conter no mínimo 6 caracteres!");
        }
      } else {
        setMsgErro("Por favor, informe um e-mail válido!");
      }
    } else {
      setMsgErro("Por favor, preencha todos os campos!");
    }
  }

  return (
  <>
    <Loading visible={loading}/>
    <div className="container">
      <div id="cadastro" className="col-12 col-md-8 offset-md-2">
        <div className="text-center">
          <br/> <br/> <br/>
          <img id="logo" src={logo}/>
          <br/>
          <h3>Cadastro {category}</h3>
          <p>{subTitle}</p>
        </div> 
        <br/>
        <form onSubmit={handleSubmit}>
          <div className="form-group col-12">
            <label className="labelInput">Nome: *</label>
            <input 
              required
              type="text" 
              className="form-control" 
              placeholder={category === "Prestador" ? "Seu nome" : "Nome do seu negócio"}
              value={name}
              onChange={event => setName(event.target.value)}/>
          </div>
          <div className="form-group col-12">
            <label className="labelInput">E-mail: *</label>
            <input 
              required
              type="email" 
              className="form-control" 
              placeholder={category === "Prestador" ? "Seu e-mail" : "E-mail do seu negócio"}
              value={email}
              onChange={event => setEmail(event.target.value)}/>
          </div>            
          <div className="form-group col-12">
            <label className="labelInput">Senha: *</label>
            <input 
              required
              type="password"  
              className="form-control" 
              name="senha" id="senha" 
              placeholder="Sua senha"
              value={pass}                
              onChange={event => setPass(event.target.value)}/>
          </div>
          <div className="form-group col-12">
            <label className="labelInput">Confirmar Senha: *</label>
            <input 
              required
              type="password"  
              className="form-control" 
              name="senha" id="senha" 
              placeholder="Sua senha"
              value={passConfirm}                
              onChange={event => setPassConfirm(event.target.value)}/>
          </div>
          <div className="form-group col-12">
            <label className="labelInput">Estado: *</label>
            <select className="form-control" value={state} onChange={event => setState(event.target.value)} required >
              <option></option>
              {states.map((item) => {
                if(item.nome !== state){
                  return(
                    <option key={item.state} value={item.state}>{item.state}</option>
                  );
                }
              })}
            </select>
          </div>
          <div className="form-group col-12">
            <label className="labelInput">Cidade: *</label>
            <select className="form-control" onChange={event => setCity(event.target.value)} value={city} required >
              <option></option>
              {cities.map((item) => {
                if(item.state === state){
                  return(
                    <option key={item.city} value={item.city}>{item.city}</option>
                  );
                }
              })}
            </select>
          </div>
          <div className="form-group col-12">
            <label className="labelInput">{category === "Prestador" ? "Selecione sua área de atuação" : "Selecione a categoria do seu comércio"}</label>
            <select className="form-control" value={servicoSelected} onChange={event => setServicoSelected(event.target.value)} required >
              <option></option>
              {servicos.map((item) => {
                return(
                  <option key={item._id} value={item._id}>{item.name}</option>
                );
              })}
            </select>
          </div>
          <p className={`col-12 alert alert-danger ${msgErro === "" ? 'hidden' : ''}`} onClick={() => setMsgErro("")}>{msgErro}</p>
          <br/>
          <button type="submit" className="btn btn-default col-8 offset-2">Criar Conta</button>
          <br/><br/>
        </form>
      </div>
    </div>
</>);
}

export default Cadastro;
